import React from 'react'

import styles from './Marker.module.scss'
import electric_icon from '../../../static/img/map/electric-icon.png'
import Icons from './Icons'

export default ({ product, selected }) => {
  let { price_d, electric, type } = product
  return (
    <div className={styles.markerContainer}>
      <div className={[styles.marker, selected && styles.selected].join(' ')}>
        <div className={styles.priceTag}>${price_d / 100}</div>
        {electric && (
          <img
            src={electric_icon}
            height="20"
            className={styles.electricIcon}
          />
        )}
        <Icons type={type} highlight={selected} height="20" />
      </div>
    </div>
  )
}
