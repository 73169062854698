import React from 'react'

import SearchItems from 'components/Searchbar/SearchItems'
import Categories from './fields/Categories'
import DatePickerButton from 'components/shared/DatePicker/DatePickerButton'
import styles from './QueryBox.module.scss'

export default ({}) => {
  return (
    <div className={styles.queryBox}>
      <div className={styles.primary}>
        <SearchItems />
      </div>
      <div className={styles.secondary}>
        <Categories />
      </div>
    </div>
  )
}
