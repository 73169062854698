import React from 'react'
import { Link } from 'gatsby'

import styles from './SearchNotice.module.scss'
import { AuthService } from 'services'
import { RightArrow } from 'components/shared/Icons'

export default () => {
  if (AuthService.auth.currentUser) {
    return <div />
  }
  return (
    <div className={styles.container}>
      Our members get unlimited access to listed rentals and exclusive deals.{' '}
      <Link to="users/sign_up">
        Signup now <RightArrow />
      </Link>
    </div>
  )
}
