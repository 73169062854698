import React from 'react'
import { InstantSearch, ScrollTo } from 'react-instantsearch-dom'
import { inject, observer } from 'mobx-react'

/* needed ? */
import 'instantsearch.css/themes/algolia.css'
import './styles.scss'
import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import ResultsMap from 'components/SearchPage/ResultsMap'
import Results from 'components/SearchPage/Results'
import QueryBox from 'components/SearchPage/QueryBox'
import keys from 'constants/keys'
import HierarchicalMenu from 'components/SearchPage/fields/Categories'
import { SearchController } from '../../SearchStore'

@inject('searchStore')
@observer
class SearchPage extends React.Component {
  state = {
    hoveredItemId: null,
  }
  /**
   * Set the search state based on the url
   * TODO: Should also try and read preferences
   *  or settings from storage
   */
  componentDidMount() {
    const queryString = this.props.location.search.slice(1)
    if (queryString) {
      this.props.searchStore.setStateFromQueryString(queryString)
    } else {
      this.props.searchStore.reset()
    }
  }

  onSearchState = (state, store) => {
    const qs = store.queryString
    const url = `${this.props.location.pathname.replace(/^(.*)\/$/, '$1')}${qs}`
    typeof window !== 'undefined' &&
      window.history.replaceState(null, null, url)
    // scroll to the top of the results whenever state changes
    document.querySelector('#scroll-container').scrollTop = 0
  }

  onSearchStateChange = newState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      // TODO: This should be some kind of stateful "action"
      // or at the very least go into its own API
      const url = searchStateToUrl(this.props, newState)
      typeof window !== 'undefined' && window.history.pushState(null, null, url)
      typeof window !== 'undefined' &&
        window.sessionStorage.setItem('mellow_last_url', `${url}`)
    }, 0.7 * 1000)

    this.setState({
      searchState: {
        // ...oldState,
        ...newState,
        // Important to keep the boundingBox empty
        boundingBox: null,
      },
    })
  }

  onHoverItem = id => {
    this.setState({ hoveredItemId: id })
  }

  onLeaveHoverItem = () => {
    this.setState({ hoveredItemId: null })
  }

  onClickMarker = ({ hit, marker }) => {}

  render() {
    return (
      <InstantSearch
        appId={keys.ALGOLIA.APP_ID}
        apiKey={keys.ALGOLIA.API_KEY}
        indexName={keys.ALGOLIA.INDEX}
        refresh={true}
      >
        <div className="app">
          <SearchController onSearchState={this.onSearchState} />

          <div className="search-main">
            <div className="results-container">
              <QueryBox />
              <Results
                onHoverItem={this.onHoverItem}
                onLeaveHoverItem={this.onLeaveHoverItem}
              />
            </div>
            <div className="map-container">
              <ResultsMap
                className="map"
                hoveredItemId={this.state.hoveredItemId}
                onMapCenterChanged={() => {}}
                onClickMarker={this.onClickMarker}
              />
            </div>
          </div>
        </div>
      </InstantSearch>
    )
  }
}

export default ({ data, location }) => (
  <Layout
    displayFooter={false}
    location={location}
    navbarProps={{
      showSearchBar: true,
    }}
    viewportProps={{ className: 'app-viewport' }}
  >
    <SEO title="Search For Items on Melo" />
    <SearchPage data={data} location={location} />
  </Layout>
)
