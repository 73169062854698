import React, { useState } from 'react'
import { observer, inject } from 'mobx-react-lite'
import { searchStore } from '/SearchStore.js'

import './Categories.scss'
import * as categories from 'constants/categories'
import { slugify } from 'utils/conversions'

function Categories(props) {
  const [isOpen, setOpen] = useState(false)
  const [categoryName, setCategoryName] = useState(null)
  const toggle = val =>
    setOpen(prev => (typeof val === 'boolean' ? val : !prev))

  return (
    <div>
      <div
        className={['refinement-mobile-toggle'].join(' ')}
        onClick={() => toggle()}
      >
        {categoryName ? categoryName : 'All Items'}
      </div>
      <ul
        className={['refinement-list', isOpen && 'list-open']
          .filter(Boolean)
          .join(' ')}
      >
        {Object.keys(categories.ITEM_CATEGORIES).map(type => {
          const category = `type:${slugify(type)}`
          const isSelected = searchStore.hasCategory(category)
          if (isSelected && !categoryName) {
            setCategoryName(type)
          }
          return (
            <li
              key={type}
              className={['refinement-list-item', isSelected && 'selected']
                .filter(Boolean)
                .join(' ')}
              onClick={event => {
                event.preventDefault()
                // toggle dropdown
                toggle(false)

                // de-togglee category on second click
                if (searchStore.hasCategory(category)) {
                  return searchStore.setCategory(null)
                }
                setCategoryName(type)
                searchStore.setCategory(category)
              }}
            >
              <a className="refinement-list-item-label">{type}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default observer(Categories)
