import React from 'react'

import ItemHits from './ItemHits'
import SearchNotice from './SearchNotice.js'
import { searchStore } from '/SearchStore.js'
import Pagination from './Pagination'
import styles from './Results.module.scss'

export default function Results(props) {
  function onPageChange(data) {
    searchStore.setPage(data.selected)
  }
  return (
    <div className={styles.container} id="scroll-container">
      <SearchNotice />
      <ItemHits
        onHoverItem={props.onHoverItem}
        onLeaveHoverItem={props.onLeaveHoverItem}
      />
      <Pagination onChange={onPageChange} />
    </div>
  )
}
